/*
 * Control the animation type and timing in relation to the scrolling position
 * */
WZFX = (function(win){
	var sm_controller = new ScrollMagic.Controller();
	
	var gsaptweens = {
			appear_from_left: {
				direction: 'from',
				delay: 1,
				fx: {autoAlpha:0, x: '-50%', y: '20%'}
			},
			appear_from_right: {
				direction: 'from',
				delay: 1,
				fx: {autoAlpha:0, x: '50%', y: '20%'}
			},
			fadeIn: {
				direction: 'from',
				delay: 1,
				fx: {autoAlpha:0, y: '20%'}
			}
	};
		
	var set_scene = function(el) {
		/* Handle scene duration */
		var duration = el.getAttribute('data-msduration') * 1;
		/* Handle animation offset from the scene trigger */
		var offset = el.getAttribute('data-msoffset') * 1;
		/* Set the scene trigger element - when this element hits the threashold (enter, leave, etc..), the fireworks start */
		var trigger = el.getAttribute('data-mstrigger');
		/* Handle pinning */
		var is_sticky = ('true' === el.getAttribute('data-sticky'))?true:false;
		
		//if (isNaN(duration) || isNaN(offset)) return;
		
		var sm_scene_options = {triggerHook: 1};
		if (!isNaN(duration)) {
			sm_scene_options.duration = duration; // the scene should last for a scroll distance of 'duration'
		}
		if (!isNaN(offset)) {
			sm_scene_options.offset = offset; // start this scene after scrolling for 'offset'
		}
		if (trigger !== '') {
			sm_scene_options.triggerElement = trigger;
		}
		var sm_scene = new ScrollMagic.Scene(sm_scene_options);
		
		if (is_sticky) {
	    	sm_scene.setPin(el); // pins the element for the scene's duration
	    }
		
		/* GSAP effects */
		var gsap_fx_label = el.getAttribute('data-gsaptween');
		if (gsap_fx_label !== '' && gsap_fx_label !== null) {
			var gsap_fx = gsaptweens[gsap_fx_label]; // Pull the tween effect object from the tweens array
			var fx_direction = gsap_fx.direction;
			var fx_delay = gsap_fx.delay;
			var fx = gsap_fx.fx;
			var tween = new TimelineMax();
			tween.from(el, fx_delay, fx);
			sm_scene.setTween(tween);
		}
		
		sm_scene.addTo(sm_controller); // assign the scene to the controller
	};
	
	var init_sm_controller = function () {
		var sm_controller = new ScrollMagic.Controller();
		
		/* customized animations  */
		var sm_elements = document.querySelectorAll('.smgcel');
		if (sm_elements.length) {
			Array.apply(null, sm_elements).forEach(function (el,i) {
				set_scene(el);
			});
		}
		if (window.innerWidth > 767) {
			/* Parallax */
			var parallax_bg_elements = document.querySelectorAll('.parallax_el');
				
			if (parallax_bg_elements.length) {
				Array.apply(null, parallax_bg_elements).forEach(function (el,i) {
					var parallax_el_holder = el.parentNode;
					parallax_el_holder.classList.add('parallax_el_holder');
					el.style.height = parallax_el_holder.offsetHeight * 1.4 + 'px';
					var parallax_tl = new TimelineMax();
					parallax_tl
						.from(el, 1, {y: '-30%', ease: Power0.easeNone},0);
					var sm_scene = new ScrollMagic.Scene({
						triggerElement: parallax_el_holder,
						triggerHook: 1,
						duration: '150%'}) /* The lesser duration - the faster movement */
						.setTween(parallax_tl) /* The bigger offset the more distinct movement */
						.addTo(sm_controller);
					/* In case there is a black strip below, increase the element height to be equivalent to its initial offset. I.g. - height: 140%, offset -50% */
				});
			}
		}
		/* Rollable (legacy) */
		var rollables = document.querySelectorAll('.rollable');
		if (rollables.length) {
			Array.apply(null, rollables).forEach(function (el,i) {
				var hook = el.getAttribute('data-hook');
				var trigger = el.getAttribute('data-trigger');
				if ('' === hook || 'null' == hook || null == hook) {
					hook = 1;
				}
				if ('' === trigger || 'null' == trigger || null == trigger) {
					trigger = el;
				}
				
			//	alert(el.className + ': Hook: ' + hook + ' trigger: ' + trigger);
			  //var sm_scene = new ScrollMagic.Scene({triggerElement: el.parentNode, triggerHook: 0, offset: 10})
				var sm_scene = new ScrollMagic.Scene({triggerElement: el, triggerHook: hook, offset: 10})
					.setClassToggle(el, 'rolling')
					.addTo(sm_controller);
			});
		}
		
		/* Animate.css  */
		var animated = document.querySelectorAll('.animated');
		if (animated.length) {
			Array.apply(null, animated).forEach(function (el,i) {
				var fx_class = el.getAttribute('data-cssanim');
				var sm_scene = new ScrollMagic.Scene({triggerElement: el, triggerHook: 1, offset: 0})
					.setClassToggle(el, fx_class)
					.addTo(sm_controller);
			});
		}
		var sticky = document.querySelectorAll('.sticky');
		if (sticky.length && get_viewport_width() > 767) {
			Array.apply(null, sticky).forEach(function (el,i) {
				var duration_offset = $(document).height();
				var el_height = el.offsetHeight; 
				
				duration_offset_elref = el.getAttribute('data-duration-offset-el');
				if (duration_offset_elref !== '') {
					duration_offset = $(duration_offset_elref).height();
				}

				var additional_offset = el.getAttribute('data-additional-offset')*1;
				additional_offset = (isNaN(additional_offset)?0:additional_offset);
				duration_offset += additional_offset; 
				
				if (duration_offset > el_height) {
					duration_offset = duration_offset - el_height;
					var sm_scene = new ScrollMagic.Scene({
							triggerElement: el, 
							duration: duration_offset,
							triggerHook: 0
						})
						.setPin(el)
						.addTo(sm_controller);
				}
			});
		}
	};
	var love_first_letters = function() {
		var elements = document.getElementsByClassName("each-word");
		for (var i=0; i<elements.length; i++){
			elements[i].innerHTML = elements[i].innerHTML.replace(/\b([a-z])([a-z]+)?\b/gim, "<span class='first-letter'>$1</span>$2");
		}
	};
	var absolute_squares = document.querySelectorAll('.absolute_square');
	if (absolute_squares.length) {
		Array.apply(null, absolute_squares).forEach(function (el,i) {
			el.style.height = el.offsetWidth + 'px';
		});
	}
  //Can also be an object constructor or whatever
	return {
		init: function(){
			init_sm_controller();
			love_first_letters();
	    }
	};

})(window);


if (window.addEventListener) {
	window.addEventListener("load", WZFX.init, false);
}
else if (window.attachEvent) {
	window.attachEvent("onload", WZFX.init);
}
else {
	window.onload = WZFX.init;
}



/* 
 *
 * Simple animate.css animationm:
 * Add class animated and data-cssanim="the requested animation"
 * View animation options here: https://daneden.github.io/animate.css/
 * 
 * Define an element for scrollmagic:
 * set a class smgcel
 * set a duration and offset using data-msduration and data-offset
 * make it sticky with data-mssticky="true"
 * set it's trigger element using data-mstrigger="my_trigger_element_name"
 * toggle a css class when triggered with data-cssanim="classname"
 * Set a gsap effect on the element from the pre-defined effects (in the gsaptweens object) with data-gsaptween
 * 
 * <div class="sm_el" data-duration="100" data-offset="50" data-sticky="true" data-trigger="true">
 * 
 * 
 * Define a parallax element for scrollmagic:
 * Add a class parallax_el to the animated element that moves in the background
 * 
 * 
 */


/*
 * Need to add gsap plugin for scrollmagic 
 * 
 */