/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
  	  // JavaScript to be fired on all pages
      init: function() {
          $("#nav-primary").mmenu({
        navbar: {
          title: "Menu"
        },
        "navbars": [
          {
              "position": "top"
          },
          {
              "position": "bottom",
              "content": [
                  "<a class='fa fa-envelope' href='#/'></a>",
                  "<a class='fa fa-twitter' href='#/'></a>",
                  "<a class='fa fa-facebook' href='#/'></a>"
              ]
            }
          ]
        }, {
              // configuration
              clone: true,
              offCanvas: {
                  pageSelector: "#wrapper"
              }
        });
        $('.wz_slideshow_holder').css('height', 'auto');
        $('.fancybox').fancybox();
        $('.scroll_down_btn').click(function () {
          var goto_target = $(this).data('goto');
          goToByScroll(goto_target);
        });
        /* Isotope (masonry) grid on the exercises page */
        $('.grid').isotope({
          // options
          itemSelector: '.grid-item',
          layoutMode: 'fitRows'
        });
        var sorted_title_id = '#sorted_exercise_title';
        var default_title = $(sorted_title_id).html();
        var sorted_aids_id = '#sorted_aids';
        var default_aids_title = $(sorted_aids_id).html();
        var bodypart = 'all';
        var training_aid = 'all';
        filter_xrs ();
        $('.filter').each(function (i, item) {
            $(item).click(function (){
                var all_displayed;
                var is_training_aids = 'workout-training-aid' == item.parentNode.id;
                $(item).parent().find('.filter').each(function (m, sibling) {
                  $(sibling).removeClass('active');
                });
                if (is_training_aids) {
                  training_aid = $(item).data('filter').replace(/^\./,'');
                }
                else {
                  bodypart = $(item).data('filter').replace(/^\./,'');
                }

                filter_by(bodypart, training_aid);

                if ('all' == bodypart && 'all' == training_aid) {
                  all_displayed = true;
                }
                else {
                  all_displayed = false;
                }

                if(!all_displayed) {
                  var document_hash = (bodypart + '_' + training_aid).replace(/\./g,'');
                  document.location.hash = document_hash;
                }
                else {
                  document.location.hash = '';
                }
          });
        });
        if (get_viewport_width() < 768) {

        }
        else {
          if(document.getElementById('filter_panel_toggler')) {
              document.getElementById('filter_panel_toggler').onclick = function () {
                if (this.parentNode.classList.contains('closed')) {
                    this.parentNode.classList.remove('closed');
                    this.parentNode.classList.add('open');
                }
                else {
                    this.parentNode.classList.remove('open');
                    this.parentNode.classList.add('closed');
                }
                return false;
              }
          }
            /* Adding video */
            if (document.getElementById('video_bg_holder')) {
                var video_obj = document.createElement('video');
                var video_container = document.getElementById('video_bg_holder');
                $(video_container).find('> span.video_src').each(function (i, el) {
                    var video_src = $(el).data('src');
                    var video_type = $(el).data('type');
                    var video_src_el = document.createElement('source');
                    video_src_el.src = video_src;
                    video_src_el.setAttribute('type', 'video/' + video_type);
                    video_obj.append(video_src_el);
                    video_obj.style.display = 'block';
                });
                video_container.append(video_obj);
                video_obj.setAttribute('autoplay',true);
                video_obj.setAttribute('loop',true);
            }


            // init Isotope
            var $grid = $('.masonry-container').isotope({
                percentPosition: true,
                itemSelector: '.grid-item',
                masonry: {
                    // use element for option
                    columnWidth: '.grid-item'
                }
            });
            // layout Isotope after each image loads
            $grid.imagesLoaded().progress( function() {
                $grid.isotope('layout');
            });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
        finalize: function() {
            // JavaScript to be fired after the init JS
        }
    },
	'anatomical_map': {
          init: function() {
              // JavaScript to be fired on the about us page
			  $('.muscleitem').each(function (i, item) {
			  	var muscleslug = $(item).data('muscleslug');
			  	$(item).click( function () {
			  		$('.inner_muscle_container').find('.musclebox').hide();
                    $('.inner_muscle_container').find('.muscleslug_' + muscleslug).show();
				});

			  });
              var movement_section;
			  $('.movement_title').each(function () {
			      $(this).click(function (){
                      movement_section = $(this).data('movementid');
			          $('#' + movement_section).slideToggle();
                  });
              });
          },
          finalize: function() {
              // JavaScript to be fired after the init JS
          }
	  },
      'single_excercise': {

      },
      'print_program': {
        init: function () {
            $('#printit').click(function () {
                window.print();
            });
        }
      }
  };


  function manage_program() {
    var ajax_url = $(this).data('ajaxurl');
    var xr_id = $(this).data('xrid');
    var the_nonce = $(this).data('nonce');
    var xr_action = $(this).data('todo');
    $.ajax({
        type: 'post',
        url: ajax_url,
        data: {
            action: 'manage_program',
            xr_id: xr_id,
            add2program_nonce: the_nonce,
            xr_action: xr_action
        },
        success: function(response) {
            $('#program_tbody').html(response);
            $('.xr_remove').each(function (i, item) {
                $(item).click(function() {
                    remove_item(this);
                });
            });
        }
    });
  }

    function remove_item(o) {
        var ajax_url = $(o).data('ajaxurl');
        var xr_id = $(o).data('xrid');
        var the_nonce = $(o).data('nonce');
        var xr_action = $(o).data('todo');
        $.ajax({
            type: 'post',
            url: ajax_url,
            data: {
                action: 'remove_item',
                xr_id: xr_id,
                add2program_nonce: the_nonce,
                xr_action: 'remove'
            },
            success: function(response) {
                var current_item = $(o).closest('tr');
                current_item.fadeOut(function () {
                    var related_bodypart_id = current_item.data('bodypart');
                    var all_items_related_to_bodypart = $('.related2' + related_bodypart_id);
                    var all_items_removed = true;
                    all_items_related_to_bodypart.each(function () { /* Hide the line that should be removed from the session */
                        if ($(this).is(':visible')) {
                            all_items_removed = false; /* Somewhere over the rainbow there's the simple way to do this. I am banned from this place. */
                        }
                        /* If all items related to this bodypart are hidden/ removed, then remove also the bodypart title. */
                        if(all_items_removed) {
                            $('#bodypart-title' + related_bodypart_id).fadeOut();
                        }
                    });
                });
            }
        });
    }
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function get_viewport_width() {
	/* https://andylangton.co.uk/blog/development/get-viewportwindow-size-width-and-height-javascript */
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],viewport_width=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return viewport_width;
}


function goToByScroll(target){
	if (!jQuery(target).length) return;

	var scrolltop_position = ('top' == target)?0:jQuery(target).offset().top;
	scrolltop_position += -150;
    jQuery('html,body').animate(
		{scrollTop: scrolltop_position},{
			duration: 1000,
			easing: 'swing'
		}
	);
}

function readCookie(name) {
    /* Thanks Vignesh Pichamani https://stackoverflow.com/questions/1458724/how-do-i-set-unset-a-cookie-with-jquery#answer-18652401 */
    var keyValue = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function filter_xrs() {/* Sort the exercises on the page according to the location hash */
    if ('#all_all' == document.location.hash) {/* impossible to sort out a lonely '#' */
       document.location = document.location.href.replace(document.location.hash,'');
    }
    else if (document.location.hash != '') {
        jQuery('.filter').each(function (i,item) {
            jQuery(item).removeClass('active');
        });
        var hash_arr = document.location.hash.split('_');
        var bodypart = hash_arr[0].replace('#','');
        var training_aid = hash_arr[1].replace('#','');
        filter_by(bodypart, training_aid);
    }
}

function filter_by(bodypart, training_aid) {
    var bp_filter;
    var ta_filter;
    var sorted_title_text;
    var sorted_aids_text;
    var sorted_title_id = '#sorted_exercise_title';
    if ('all' == bodypart) {
        sorted_title_text = '';
    }
    else {
        if (document.getElementById('bp_' + bodypart)) {
            /* Take care of the filter box active items */
            bp_filter = document.getElementById('bp_' + bodypart);
            bp_filter.classList.add('active');
            /* Take care of the page title */
            sorted_title_text = ' עבור ' + jQuery(bp_filter).find('img').attr('title')
        }
    }
    if ('all' == training_aid) {
        sorted_aids_text = '';
    }
    else {
        if (document.getElementById('ta_' + training_aid)) {
            /* Take care of the filter box active items */
            ta_filter = document.getElementById('ta_' + training_aid);
            ta_filter.classList.add('active');
            /* Take care of the page title */
            sorted_aids_text = ' כנגד ' + jQuery(ta_filter).find('img').attr('title')
        }
    }
    document.getElementById('sorted_exercise_title').innerHTML = sorted_title_text + ' ' + sorted_aids_text;
    /* Take care of the exercise filters */
    var filterValue = ''
    jQuery('.filter.active').each(function (n, active_filter) {
        filterValue += jQuery(active_filter).data('filter');
    });
    jQuery('.grid').isotope({ filter: filterValue });

    goToByScroll(sorted_title_id);
}