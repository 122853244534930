training_program_manager = (function(win){
    var ajaxurl     = jQuery('#show_program').data('ajaxurl');
    var the_nonce   = jQuery('#show_program').data('nonce');
    var set_program_detail = function (detail_key, detail_value) {
        jQuery.ajax({
            type: 'post',
            url: ajaxurl,
            data: {
                action: 'update_program',
                detail_key: detail_key,
                detail_value: detail_value,
                add2program_nonce: the_nonce
            },
            success: function(response) {

            }
        });
    }
    var update_exercise = function(xr_id, total_sets, total_reps, weight, comment, bodypart, the_action) {
        jQuery.ajax({
            type: 'post',
            url: ajaxurl,
            data: {
                action: the_action,
                xr_id : xr_id,
                total_sets: total_sets,
                total_reps: total_reps,
                weight: weight,
                comment: comment,
                bodypart: bodypart,
                add2program_nonce: the_nonce
            },
            success: function(response) {
                if ("display_program" == the_action) {
                    jQuery('#program_tbody').html(response);
                    jQuery('.program_detail').each(function (i, item){
                        jQuery(item).on('blur', function () { // If 'blur' doesn't do the job right - use 'input'
                            var detail_key = jQuery(this).data('detail');
                            var detail_value = this.value;
                            set_program_detail(detail_key, detail_value);
                        });
                    });
                    jQuery('.update_exercise').each(function (i, item) {
                        jQuery(item).on('blur', function () {
                            var xr_id = jQuery(this).data('itemid');
                            var total_sets = jQuery('#total_sets' + xr_id).val();
                            var total_reps = jQuery('#total_reps' + xr_id).val();
                            var weight = jQuery('#weight' + xr_id).val();
                            var comment = jQuery('#comment' + xr_id).val();
                            var bodypart = jQuery(this).parent().data('belogns2bodypart');
                            update_exercise(xr_id, total_sets, total_reps, weight, comment, bodypart, 'update_program_item');
                        })
                    });
                    jQuery('.xr_remove').each(function (i, item) {
                        jQuery(item).click(function() {
                            var item_id = jQuery(this).data('itemid');
                            delete_exercise(item_id);
                        });
                    });
                }
            }
        });
    }
    var delete_exercise = function(item_id) {
        jQuery.ajax({
            type: 'post',
            url: ajaxurl,
            data: {
                action: 'delete_program_item',
                xr_id : item_id,
                add2program_nonce: the_nonce
            },
            success: function(response) {
                var returned_id = response;
                jQuery('#itemline' + response).fadeOut();
            }
        });
    }

    return {
        init: function(){
            jQuery('.program_detail').each(function (i, item){
                jQuery(item).on('blur', function () { // If 'blur' doesn't do the job right - use 'input'
                    var detail_key = jQuery(this).data('detail');
                    var detail_value = this.value;
                    set_program_detail(detail_key, detail_value);
                });
            });
            jQuery('.update_exercise').each(function (i, item) {
                jQuery(item).on('blur', function () {
                    var xr_id = jQuery(this).data('itemid');
                    var total_sets = jQuery('#total_sets' + xr_id).val();
                    var total_reps = jQuery('#total_reps' + xr_id).val();
                    var weight = jQuery('#weight' + xr_id).val();
                    var comment = jQuery('#comment' + xr_id).val();
                    var bodypart = jQuery(this).parent().data('belogns2bodypart');
                    update_exercise(xr_id, total_sets, total_reps, weight, comment, bodypart, 'update_program_item');
                })
            });
            jQuery('.xr_remove').each(function (i, item) {
                jQuery(item).click(function() {
                    var item_id = jQuery(this).data('itemid');
                    delete_exercise(item_id);
                });
            });
            jQuery('#add2program').click(function () {
                var xr_id = jQuery(this).data('xrid');
                var bodypart_id = jQuery(this).data('bodypartid');
                var reps = 12;
                var sets = 3;
                var weight = '';
                var comments = '';
                update_exercise(xr_id, sets, reps, weight, comments,bodypart_id,'display_program');
            });
        }
    };

})(window);

if (window.addEventListener) {
    window.addEventListener("load", training_program_manager.init, false);
}
else if (window.attachEvent) {
    window.attachEvent("onload", training_program_manager.init);
}
else {
    window.onload = training_program_manager.init;
}
